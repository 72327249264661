import React from "react";
import "./homepage.css";

const Footer = () => {
  return (
    <div>
      <section className="newsletterSection">
        <div className="OfferContainer">
          <h3> Get Exclusive Offers</h3>
          <p>Subscribe to get notified about latest exciting offers!</p>

          <form>
            <input type="text" placeholder="Enter your Email" />
            <button>Subscribe Now</button>
          </form>
        </div>
        <div className="SocialContainer">
          <h3>Stay Connected</h3>
          <p>View our social media activities</p>
          <ul>
            <li>
              <i
                class="bx bxl-facebook-circle"
                style={{ color: "#085dce", fontSize: "35px" }}
              ></i>
            </li>
            <li>
              <i
                class="bx bxl-youtube"
                style={{ color: "#ce080b", fontSize: "40px" }}
              ></i>
            </li>
            <li>
              <i
                class="bx bxl-instagram-alt"
                style={{ color: "#dd056c", fontSize: "40px" }}
              ></i>
            </li>
          </ul>
        </div>
      </section>
      <section className="FooterSection">
        <div className="LogoSection">
          <img src="assets/logo_partex.png" />
          <p>
            Partex Star Group is one of the largest Bangladeshi diversified
            private sector enterprises. The Group, the then Partex Group,
            started its journey in 1962, with the torchbearer, Mr. M.A. Hashem,
            the founder Chairman of the then Partex Group, an eminent
            industrialist of the country.
          </p>
        </div>
        <div className="LinkSection">
          <h3>Important Links</h3>
          <div>
            <ul>
              <li>Boards</li>
              <li>Doors</li>
              <li>Contact</li>
              <li>About</li>
              <li>Policy</li>
              <li>Conditions</li>
            </ul>
          </div>
        </div>
        <div className="AddressSection">
          <h3>Address</h3>

          <p>
            <i class="bx bx-home"></i>
            Shanta Western Tower, <br />
            Level – 13 Bir Uttam Mir Shawkat Road,
            <br /> 186 Tejgaon I/A <br />
            Dhaka – 1208, Bangladesh
          </p>
          <p>
            <i class="bx bx-phone-call"></i>PABX : +88.02. 8878800-11
          </p>
          <p>
            <i class="bx bx-envelope"></i>E-mail : mail@psgbd.com
          </p>
          <p>
            <i class="bx bxl-telegram"></i>Fax : +88.02. 8878815
          </p>
        </div>
        <div className="SocialmediaSection">
          <h3>Social Media</h3>
          <ul>
            <li>
              <i
                class="bx bxl-facebook-circle"
                style={{ color: "white", fontSize: "35px" }}
              ></i>
            </li>
            <li>
              <i
                class="bx bxl-youtube"
                style={{ color: "white", fontSize: "40px" }}
              ></i>
            </li>
            <li>
              <i
                class="bx bxl-instagram-alt"
                style={{ color: "white", fontSize: "40px" }}
              ></i>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Footer;
