import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./homepage.css";

const BoardSection = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  return (
    <section className="BoardSection">
      <div className="TitleContainer">
        <h3 style={{ color: "red" }}>Partex Boards</h3>
        <hr />
        <p>Carpenters Choose Partex, You Choose To Trust</p>
      </div>
      <div className="BoardPromoContainer">
        <div className="promoSection1">
          <img className="promoImage1" src="assets/Board/promo_board1.jpeg" />
          <img className="promoImage2" src="assets/Board/promo_board2.jpeg" />
          <img className="promoImage1" src="assets/Board/promo_board4.jpeg" />
          <img className="promoImage2" src="assets/Board/promo_board3.jpeg" />
        </div>
        {/* <div className="promoSection2">
          <img className="promoImage" src="assets/Board/promo_board4.jpeg" />
          <img className="promoImage" src="assets/Board/promo_board3.jpeg" />
        </div> */}
      </div>
      <div className="BoardSectionContainer">
        <p>Natural Textures</p>
        <hr />
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={1800}
          keyBoardControl={true}
          arrows={false}
          containerClass="carousel-container"
        >
          <img className="boardImage" src="assets/Board/nature_board1.jpeg" />
          <img className="boardImage" src="assets/Board/nature_board2.jpeg" />
          <img className="boardImage" src="assets/Board/nature_board3.jpeg" />
          <img className="boardImage" src="assets/Board/nature_board4.jpeg" />
          <img className="boardImage" src="assets/Board/nature_board5.jpeg" />
        </Carousel>
      </div>
    </section>
  );
};

export default BoardSection;
