import React from "react";
import "./homepage.css";

const DoorSection = () => {
  return (
    <section class="DoorImageContainer overflow-hidden text-gray-700">
      <div className="TitleContainer">
        <h3 style={{ color: "red" }}>Partex Doors</h3>
        <hr />
        <p>Quality Doors from a Trusted House</p>
      </div>
      <div class="DoorGalleryContainer">
        <div class="flex flex-wrap -m-1 md:-m-2">
          <div class="flex flex-wrap w-1/2">
            <div class="w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                class="smallImage block object-cover object-center w-full rounded-lg"
                src="assets/Solid Wooden Design Doors.png"
              />
            </div>
            <div class="w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                class="smallImage block object-cover object-center w-full h-full rounded-lg"
                src="assets/Laquered Door.png"
              />
            </div>
            <div class="w-full p-1 md:p-2">
              <img
                alt="gallery"
                class="bigImage block object-cover object-center w-full h-full rounded-lg"
                src="assets/door_promo2.png"
              />
            </div>
          </div>
          <div class="flex flex-wrap w-1/2">
            <div class="w-full p-1 md:p-2">
              <img
                alt="gallery"
                class="bigImage block object-cover object-center w-full h-full rounded-lg"
                src="assets/door_promo3.png"
              />
            </div>
            <div class="w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                class="smallImage block object-cover object-center w-full h-full rounded-lg"
                src="assets/Formika Leminated Door.png"
              />
            </div>
            <div class="w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                class="smallImage block object-cover object-center w-full h-full rounded-lg"
                src="assets/Plain venered Flush Door.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DoorSection;
