import logo from "./logo.svg";
import "./App.css";
import Header from "./pages/Header";
import HeroSlider from "./pages/HeroSlider";
import ProductCard from "./pages/ProductCard";
import DoorSection from "./pages/DoorSection";
import BoardSection from "./pages/BoardSection";
import Footer from "./pages/Footer";

function App() {
  return (
    <div>
      <Header />
      <HeroSlider />
      <ProductCard />
      <DoorSection />
      <BoardSection />
      <Footer />
    </div>
  );
}

export default App;
