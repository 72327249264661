import React from "react";
import "./homepage.css";

const ProductCard = () => {
  return (
    <div className="productCardContainer">
      <div className="DoorContainer card">
        <img className="imgClass" src="assets/doors_promo.png" />
      </div>
      <div className="BoardContainer card">
        <img className="imgClass" src="assets/Board_promo.png" />
      </div>
    </div>
  );
};

export default ProductCard;
