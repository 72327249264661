import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./navbar.css";

const Header = () => {
  return (
    <nav>
      <div className="navbar">
        <i className="bx bx-menu"></i>
        <div className="logo">
          <img src="assets/logo_partex.png" />
        </div>
        <div className="nav-links">
          <div className="sidebar-logo">
            <img src="assets/logo_partex.png" />
            <i className="bx bx-x"></i>
          </div>
          <ul className="links">
            <li>
              <a href="#">HOME</a>
            </li>
            <li>
              <a href="#">ABOUT US</a>
            </li>
            <li>
              <a href="#">DOORS</a>

              <ul className="htmlCss-sub-menu sub-menu">
                <li>
                  <a href="#">Plain Veneered Flush Door</a>
                </li>
                <li>
                  <a href="#">Lacquered Doors</a>
                </li>
                <li>
                  <a href="#">Panel Design Doors</a>
                </li>
                <li>
                  <a href="#">Solid Wooden Design Doors</a>
                </li>
                <li>
                  <a href="#">Classic Design Doors</a>
                </li>
                <li>
                  <a href="#">Inlay Veneer Flush Door</a>
                </li>
                <li>
                  <a href="#">Flexible Band Design Doors</a>
                </li>
                <li>
                  <a href="#">Groove Design Doors</a>
                </li>
                <li>
                  <a href="#">Bead Design Doors</a>
                </li>
                <li>
                  <a href="#">Formica Laminated Doors</a>
                </li>
                <li>
                  <a href="#">Louver Design Doors</a>
                </li>
                <li>
                  <a href="#">Door Frames</a>
                </li>
                {/* <li>
                  <a href="#">Card Design</a>
                </li>
                <li className="more">
                  <span>
                    <a href="#">More</a>
                    <i className="bx bxs-chevron-right arrow more-arrow"></i>
                  </span>
                  <ul className="more-sub-menu sub-menu">
                    <li>
                      <a href="#">Neumorphism</a>
                    </li>
                    <li>
                      <a href="#">Pre-loader</a>
                    </li>
                    <li>
                      <a href="#">Glassmorphism</a>
                    </li>
                  </ul>
                </li>*/}
              </ul>
            </li>
            <li>
              <a href="#">BOARDS</a>

              <ul className="js-sub-menu sub-menu">
                <li className="more">
                  <span>
                    <a href="#">Plain Particle Board</a>
                  </span>

                  <ul className="more-sub-menu sub-menu">
                    <li>
                      <a href="#">Jutex Board</a>
                    </li>
                    <li>
                      <a href="#">Woodex Board</a>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="#">Veneered Board</a>
                </li>
                <li>
                  <a href="#">Plywood</a>
                </li>
                <li>
                  <a href="#">Melamine Faced Chipboard</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="#">CONTACT US</a>
            </li>
          </ul>
        </div>
        <div className="search-box">
          <i className="bx bx-shopping-bag"></i>
          <i className="bx bxs-user"></i>
          <i className="bx bx-search"></i>
        </div>
      </div>
    </nav>
  );
};

export default Header;
